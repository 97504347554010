@import "constants.scss";

@media screen and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .about {
    //font-size: 39px;
    display: block;
    //display: inline;

    @media screen and (min-width: $aboutResponsiveBreak) {
        display: flex;
        flex-direction: row;
        
      }

    max-width: 100%;
    width: 100%;
    margin-right: 0;
    //font-size: 38px;

    //   @media (max-width: $aboutResponsiveBreak) {
    //     display: block;
    //   }

    > div {
      width: 100%;
      
      min-height: 0;
      min-width: 0;

      display: inline-block;
      flex-direction: column;
      align-items: center;
      // @media screen and (-ms-high-contrast: none), (-ms-high-contrast: active), (max-width: $aboutResponsiveBreak) {
      //   display: block;

      // }
      //border: 1px red solid;
      padding-top: 50px;
      > div {
        margin-left: 40px;
        margin-right: 40px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        // @media screen and (-ms-high-contrast: none), (-ms-high-contrast: active), (max-width: $aboutResponsiveBreak) {
        //   display: block;

        // }

        //padding-right: 40px;
      }

      &:nth-child(1) {
        //flex: 6 6;
        background: url("/images/tlonowe.png");
        height: 100%;

        min-height: 350px;
        //padding: 20px;
        //background-size: auto 100%;
        background-size: 100% 100%;
        @media (max-width: $aboutResponsiveBreak) {
          text-align: center;
          background-position: 0% 50%;
          background-size: auto auto;
          background-repeat: no-repeat;
        }

        //padding-right: 50px;
        text-align: left;
        line-height: 2em;

        @media (max-width: 600px) {
          padding-bottom: 30px;
        }

        div {
          height: 100%;

          //width: 100%;
          // padding: 20px;
        }

        span {
          //display: none;
          width: 100%;
          max-width: 100%;
          //padding: 0 20px;
        }

        span.long-text {
          display: inline;
          //display: none;
        }
      }

      &:nth-child(2) {
        // position: absolute;
        // top: 0;
        // left: 0;
        // right: 0;
        height: 100%;
        // background: none;
        //   @media (max-width: $aboutResponsiveBreak) {
        //     //background-color: white;
        //     .acreditators-container {
        //       display: flex;
        //       flex-direction: row;
        //       justify-content: center;
        //     }
        //   }

        //flex: 4 4;
        //padding-top: 1.1em;
      }
    }

    hr {
      margin-left: 0;
      width: 200px;
      @include hr(black);

      @media (max-width: $aboutResponsiveBreak) {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}
