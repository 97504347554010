@import "constants.scss";

.navigation-flex {
    display: flex;
    flex-direction: row;
    align-items: center;
    img {
      width: auto;
      height: 100px;
    }
  }


  
.menu {
    display: block;
    width: 100%;
    //height: 100%;
    
    //   position: absolute;
    //   margin-left: 20px;
    //   margin-top: 20px;
    //   top: 0;
    //   left: 0;
    //   right: 0;
    //align-self: flex-start;
    display: flex;
    flex-direction: row;
    align-items: center;
    // padding: 30px;
  
    ul {
      //margin-top: -8px;
      list-style: none;
      margin: 0;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      
      li {
        margin-left: 40px;
        
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        height: 2em;
      }
    }
  
    a {
      //font-size: 1.2em;
      font-size: calc(10px + 1vmin);
      color: blue;
      cursor: pointer;
      box-sizing: padding-box;
      padding: 0 5px;
      margin-bottom: 2px;
  
      &:hover {
        padding-bottom: 5px;
        //font-weight: bold;
        border-bottom: 2px solid yellow;
        box-sizing: padding-box;
        transition: visibility 0s, opacity 0.5s linear;
      }
    }
  
    .social {
      display: inline-block;
      //justify-self: end;
      margin-left: auto;

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        display: block;
      }

      display: flex;
      flex-direction: row;
      align-items: center;
      color: white;

      .break {
          position: relative;
          top: 2px;
          margin-left: 5px;
          margin-right: 5px;
          height: 0.5em;
          //padding: 0.25em;
          background-color: white;
          width: 2px;
      }
    }
  }

  .top-menu {
    @media (max-width: $menuBreakpoint) {
        display: none;

        
      }

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        /* IE10+ CSS styles go here */
        display: flex;
   }
    
}





  footer {
    background: $blackish;
    max-width: 100%;
    padding: 30px 20px;
    //@extend .navigation-flex;

    
    display: flex;
    flex-direction: row;
    align-items: center;
    //flex-wrap: wrap;

    // div {
    //     align-self: center;
    // }

    .social {
      margin-right: 20px;
    }

    @media (max-width: 1200px) {
      display: flex;
      flex-direction: column;
      align-items: center;

      .bottom-menu {
        ul {
          list-style: none;
          padding-left: 0;
        }
      }

      .bottom-menu > ul > li > a {
        font-size: 1em;
    line-height: 1.1em;
      }

      
      
    }
    
    
  }
  @media (max-width: 1000px) {
  .bottom-menu {
 
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    width: auto;

    .social {
      margin: auto;
    }

    @media (max-width: 800px) {
      display: flex;
      flex-direction: column;

      ul {
        flex-direction: column;
        
        padding: 0;
        li {
          margin-left: 0;
        }
      }
    }
}
  }

  nav {
    position: absolute;
    top: 10px;
    left: 20px;
    right: 20px;
    @extend .navigation-flex;
  
  }