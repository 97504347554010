@import "../constants.scss";

$activeCatColor: $lightBlue;

.services-container {
  display: flex;
  flex-direction: row;
  position: relative;
  padding: 20px;
  
  max-width: 1200px;
  margin: auto;

  @media(max-width: 600px) {
    flex-direction: column;
  }

  .menu-part {
    flex: 0 1;
    min-width: 200px;
    cursor: pointer;

    display: flex;
    flex-direction: column;
    margin-right: 20px;

    @media(max-width: 600px) {
      width: 100%;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      // /border: 1px solid black;
      margin-bottom: 20px;
      box-shadow: 0px 0px 82px -15px rgba(0, 0, 0, 0.45);
    }

 

    div {
      //background: lighten(grey, 30);
      line-height: 2em;
      padding: 5px 30px;
      // font-size: 2em;

      &:hover {
        background: lighten($activeCatColor, 50);
        color: white;
      }

      &.active {
        background: $activeCatColor;
        color: white;
      }
    }
  }

  .items-part {
    margin-right: 50px;
    max-width: 40vw;
    //overflow-x: hidden;
    position: relative;

    .own-grid {
      display: grid;
      grid-column-gap: 30px;
      grid-template-columns: 1fr 1fr 1fr;

      @media(min-width: 800px) and (max-width: 1200px) {
        grid-template-columns: 1fr 1fr;
      }

      @media(max-width: 800px) {
        grid-template-columns: 1fr;
      }

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        display: block;
      }
    }
  }

  // .services-part {
    
  //   padding: 20px;
  //   align-self: flex-start;
  //   flex: 1 1;

  //   display: grid;
  //   grid-template-columns: 1fr 1fr;

  //   div {
  //     margin: 10px;
  //   }
  // }
}

.grid-cell {
  text-align: left;
  //display: block;
  max-width: 240px;

  line-height: 1.5em;
  //width: 100%;
  padding: 5px;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: block;
  }

  span {
    //margin: 5px;
  }
}