$lightBlue: #1E16B2;
$darkBlue:  #09173F;
$greyText: #939393;
$yellow: #FFEF00;
$blackish: #252525;
$orange: #FFE146;

$maxContainerWidth: 1200px;

$mobileBreakpoint: 600px;
$tabletBreakpoint: 1200px;
$menuBreakpoint: 1200px;
$aboutResponsiveBreak: 800px;




@mixin hr($color) {
  height: 3px;
  background-color: $color;
  color: $color;
  border: none;
}

@mixin biggerHeader($color) {
  display: block;
  color: $color;
  text-align: center;
  font-size: 2.5em;
  
  font-weight: bold;
  margin-bottom: 25px;
}

@mixin smallerHeader($color) {
  font-size: 1.2em;
  min-height: 1.2em;
  display: block;
  text-align: center;
  font-weight: bold;
}



.section-blue-header {
  
  
  @include biggerHeader($lightBlue);
}

.section-black-header {
  @include smallerHeader(black);
}


.inner-container {
    // max-width: $maxContainerWidth;
    //padding: 30px;
    
    padding-top: 50px;
    padding-bottom: 50px;
  }

  
