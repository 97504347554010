@supports not (-ms-high-contrast: none) {



.about {
    display: flex;
    flex-direction: row;
    
    @media not screen and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      
    min-height: 600px;
    
  
  }
  
  
    max-width: 100%;
    
    width: 100%;
    margin-right: 0;
    
  
    @media(max-width: $aboutResponsiveBreak) {
      
      flex-direction: column;
    }
  
  
  
  
    > div {
      -ms-flex: 1 1;
      flex: 1 1;
  
      
      min-height: 0;
      min-width: 0;
      width: 100%;
  
      display: flex;
      flex-direction: column;
      align-items: center;
      // @media screen and (-ms-high-contrast: none), (-ms-high-contrast: active), (max-width: $aboutResponsiveBreak) {
      //   display: block;
  
      // }
      //border: 1px red solid;
      padding-top: 50px;
      > div {
        margin-left: 40px;
        margin-right: 40px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      
        // @media screen and (-ms-high-contrast: none), (-ms-high-contrast: active), (max-width: $aboutResponsiveBreak) {
        //   display: block;
    
        // }
      
        //padding-right: 40px;
      }
  
      &:nth-child(1) {
        //flex: 6 6;
        background: url("/images/tlonowe.png");
        height: 100%;
        width: 100%;
  
        min-height: 350px;
        //padding: 20px;
        //background-size: auto 100%;
        background-size: 100% 100%;
        @media(max-width: $aboutResponsiveBreak) {
          text-align: center;
          background-position: 0% 50%;
          background-size: auto auto;
          background-repeat: no-repeat;
        }
  
        //padding-right: 50px;
        text-align: left;
        line-height: 2em;
  
        @media(max-width: 600px) {
          padding-bottom: 30px;
        }
  
        div {
          height: 100%;
         
  
          //width: 100%;
         // padding: 20px;
          
        }
  
        span {
          //display: none;
          width: 100%;
          max-width: 100%;
          //padding: 0 20px;
        }
  
        span.long-text {
          display: inline;
          //display: none;
        }
  
       
      }
  
      &:nth-child(2) {
        // background: none;
        @media(max-width: $aboutResponsiveBreak) {
        //background-color: white;
          .acreditators-container {
            display: flex;
            flex-direction: row;
            justify-content: center;
          }
        }
  
        
        //flex: 4 4;
        //padding-top: 1.1em;
      }
    }
  
    hr {
      margin-left: 0;
      width: 200px;
      @include hr(black);
  
      @media (max-width: $aboutResponsiveBreak) {
        margin-left: auto;
        margin-right: auto;
      }
    }
  
    .acreditators-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
  
      img {
        // height: 80%;
        // width: auto;
        //transform: scale(0)
  
        height: 100px;
        width: auto;
        margin: 10px;
        display: block;
        // border: 1px solid black;
        border-radius: 50%;
        -webkit-box-shadow: 0px 0px 82px -15px rgba(0, 0, 0, 0.45);
        -moz-box-shadow: 0px 0px 82px -15px rgba(0, 0, 0, 0.45);
        box-shadow: 0px 0px 82px -15px rgba(0, 0, 0, 0.45);
      }
    }
  }
}