@import "constants.scss";
@import "headerfooter.scss";

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

@import "about.scss";

#root {
  //max-width: 1920px;
  position: relative;
}
body {
//  min-width: 400px;
  font-family: 'Montserrat', sans-serif;
 
}

.App {
  margin-top: 0;
  top: 0;
  position: relative;
  text-align: center;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
  // width: 1920px;
  // position: relative;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.mobile-menu-container {
  @media (min-width: $menuBreakpoint) {
    display: none;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: none;
  }
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: blue;
  background-color: #3F3AD1;
  background: urL("/images/slice1.png");
  background-attachment: fixed;
  background-repeat: no-repeat;
  //background-position: 50% 50%;
  background-size: 100% 100vh;
  max-height: 1px;

  // @media(max-width: 1200px) {
  //   background-size: auto 100%;
  //   background-position: 50% 0;
  // }

  h1 {
    text-align: left;
    display: block;
    margin-top: auto;
    margin-bottom: auto;


    padding-left: 20px;
    border-left: solid 10px yellow;

    @media screen and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      margin-top: 400px;
    }
  }

  
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}





#our-services {
  background: urL("/images/slice3.png");
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 200px;
  padding: 50px;

  .what-we-do {
    @include smallerHeader(black);
  }

  .our-services-header {
    @include biggerHeader($lightBlue);
  }
}

#recommendations {
  background: urL("/images/slice4.png");
  //background-size: 100% auto;
  background-position: center;
  background-repeat: no-repeat;
  @media (max-width: 800px) {
    background-size: auto 100%;
    
  }
  //min-height: 1000px;
  padding-top: 30px;

  .few-words {
    color: white;
    font-size: 1.2em;
    font-weight: bold;
    display: block;
  }

  .recommendations-header {
    color: $orange;
    font-size: 2em;
    font-weight: bold;
    display: block;
  }

  hr {
    width: 200px;
    @include hr($orange);
  }

  .rec-container {
    > div {
      padding: 40px;
    }

    span {
      display: block;
      color: white;
      text-align: left;
      line-height: 2em;
    }

    .main {
      font-weight: bold;
      font-size: 1.1em;
    }

    .text {
    }

    .place {
      color: $orange;
    }
  }


  .rec-container-desktop {
    display: grid;
    grid-template-columns: 1fr 1fr;

    @media (max-width: 800px) {
      grid-template-columns: 1fr;
      display: none;
    }
  }

  .rec-cell {
    span {
      text-align: center;
    }
  }

  .rec-container-mobile {
    display: block;
    position: relative;
    padding: 30px;
    text-align: center;
    
    ul {
      left: 0;
      right: 0;
      bottom: 15px;

      li {
        //width: 40px;
      }

      button {
        // width: 40px;
        // height: 40px;
        // padding: 20px;
        margin: auto;
        

        &:before {
          font-size: 20px;
          color: $orange;
        }
      }
    }

    @media (min-width: 800px) {

      display: none;
    }
  }
}

#gallery {
  padding-top: 50px;
  .beautiful {
    color: black;
    font-size: 1.2em;
    font-weight: bold;
    display: block;
  }

  .image-gallery {
    color: $lightBlue;
    font-size: 2em;
    font-weight: bold;
    display: block;
  }

  .gallery-comp-container {
    margin-left: auto;
    margin-right: auto;
    padding: 30px;
    max-width: 960px;
  }
}

#contact {
  max-width: 960px;
  margin: auto;
  .keep {
    @include smallerHeader(black);
  }

  .contact {
    @include biggerHeader($lightBlue);
  }

  hr {
    @include hr(black);
    width: 200px;
  }

  form {
    display: block;
    max-width: 100%;
    
    margin: 30px;
    margin-right: 50px;

    input,
    textarea {
      -webkit-appearance: none;
      padding-top: 10px;
      padding-bottom: 10px;
      line-height: 2em;
      width: 100%;
      display: block;
      margin-top: 30px;
      border: 1px none grey;
      -webkit-box-shadow: 1px 0px 7px 0px rgba(0, 0, 0, 0.45);
      -moz-box-shadow: 1px 0px 7px 0px rgba(0, 0, 0, 0.45);
      box-shadow: 1px 0px 7px 0px rgba(0, 0, 0, 0.45);
      padding-left: 20px;
      resize: none;
      
    }

    button {
      cursor: pointer;
      background-color: $lightBlue;
      margin-top: 30px;
      border: none;
      color: white;
      text-align: center;
      display: block;
      font-size: 1.5em;
      padding: 15px 30px;
      margin-left: 0;
      margin-right: auto;
      border-radius: 5px;
      box-shadow: 0px 0px 12px 2px rgba(0, 0, 255, 0.45);

      &:hover {
        background-color: lighten($lightBlue, 10)
      }
    }
  }

  margin-bottom: 20px;
}


.image-gallery-play-button {
  display: none;
}

.slick-arrow {
  margin-top: -20px;
}