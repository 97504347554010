@import "aboutIE.scss";
@import "aboutRegular.scss";

.acreditators-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    width: 100%;
    max-width: 100%;
    img {
      // height: 80%;
      // width: auto;
      //transform: scale(0)

      height: 100px;
      width: auto;
      margin: 10px;
      display: block;
      // border: 1px solid black;
      border-radius: 50%;
      -webkit-box-shadow: 0px 0px 82px -15px rgba(0, 0, 0, 0.45);
      -moz-box-shadow: 0px 0px 82px -15px rgba(0, 0, 0, 0.45);
      box-shadow: 0px 0px 82px -15px rgba(0, 0, 0, 0.45);
    }
}